// import "./app.css"
import styled from "styled-components"

let AppStyled = styled.div`
  /* background-image: url("https://kod.ru/content/images/2020/09/7e49d6be8439702edf251791b465ca0e.jpg"); */
  background-color: black;
  height: 100vh;
  font-family: Roboto Mono, monospace;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  color: white;

    
  justify-items: center;
  align-items: center;
    
div{
  height: 30%;
  width: 30%;
  padding: 50px;
  /* margin-top: 150px; */
  /* background-color: grey; */
  /* display: grid; */
  border: 1px solid;
  position: relative;
    a{
      text-decoration: none;
      color: #3f7aff;
      /* padding: 5px; */
      font-size: 18px;
      /* text-align: center; */
        &:hover{
          color: blue;
      }
    }
    p{
      /* margin-top: 90px; */
      position: absolute;
      bottom: 35px;
    }
  }
  }

`

function App() {
  return (
    <AppStyled>
<div>
        <span>The Graph </span><a href="https://t.me/joinchat/FxrjmJtcs8Zz9AFy">→ telegram</a><br/>
        <span>Nucypher Pool </span><a href="https://t.me/nucypher_pool_en">→ telegram</a><br/>
        <span>Main chat </span><a href="https://t.me/nodehodler">→ telegram</a>
        <p>nodehodler.com > soon©...</p>
        
</div>
        
      
    </AppStyled>
  )
}

export default App
